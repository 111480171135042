<!-- eslint-disable vue/no-template-shadow -->

<template>
  <div>
    <b-modal
      id="modal-edit-order-item"
      ref="modal"
      header-class="headerModal"
      centered
      size="xl"
      :title="$t('EditOrderItem') + ' ' + articleNumber"
      hide-footer
      @close="onCancel"
    >
      <div
        style="    border: 1px solid gray;
    padding: 19px;"
      >
        <b-form>
          <b-form-group
            id="input-group-1"
            :label="`${$t('ArticleNumber')}:`"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="formm.articleNumber"
              aria-describedby="input-1-live-feedback"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            :label="`${$t('Size')}:`"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="formm.size"
              aria-describedby="input-1-live-feedback"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="input-group-9"
            :label="`${$t('PickupDate')}:`"
            label-for="input-9"
          >
            <flat-pickr
              v-model="formm.pickUpDate"
              class="form-control"
              :placeholder="$t('PickUpDate')"
            />
            <!-- :config="config" -->
          </b-form-group>
          <b-form-group
            id="input-group-1"
            :label="`${$t('Price')}:`"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model.number="formm.price"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            :label="`${$t('paidAmount')}:`"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model.number="formm.paidAmount"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
          </b-form-group>
        </b-form>
      </div>
      <hr>

      <!-- <section
        v-if="getEditOrder.bodyMeasurements !== null"
        class="custom-modal__wrapper"
        style="border-width: 3px; border: 1px solid gray; padding: 20px;"
      >
        <b-tabs>
          <b-tab
            :title="$t('Front Body')"
            active
          >
            <h4 class="headline">
              {{ $t('') }}
            </h4>
            <b-collapse
              id="collapse-345"
              visible
            >
              <div class="hello">

                <b-form-group
                  id="input-group-1"
                  style="width: 100%"
                  :label="`${$t('Thorax')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.thorax"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  style="width: 100%"

                  :label="`${$t('AroundBust')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.aroundBust"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>


              </div>

              <div class="hello">

                <b-form-group
                  id="input-group-1"
                  style="width: 100%"

                  :label="`${$t('AroundUnderBust')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                    v-model="formm.bodyMeasurements.aroundUnderBust"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  style="width: 100%"

                  :label="`${$t('Waist')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.waist"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>

              </div>
              <div class="hello">

                <b-form-group
                  id="input-group-1"
                  style="width: 100%"

                  :label="`${$t('BreastReduction')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.breastReduction"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  style="width: 100%"

                  :label="`${$t('ChestDistance')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.chestDistance"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="hello">
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"

                  :label="`${$t('FrontBody')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.frontBody"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  style="width: 100%"

                  :label="`${$t('AroundHips')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.aroundHips"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>

              </div>

              <div class="hello">
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"

                  :label="`${$t('AroundLeg')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.aroundLeg"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"

                  :label="`${$t('AroundKnee')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.aroundKnee"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
              </div>

              <div class="hello">
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"

                  :label="`${$t('Ankle')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.ankle"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"

                  :label="`${$t('Length1')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.length1"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
              </div>

              <div class="hello">
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"

                  :label="`${$t('Length2')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.length2"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"

                  :label="`${$t('Llength3')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.length3"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
              </div>

              <div class="hello">
                <b-form-group
                  id="input-group-1"
                  style="width: 50%"

                  :label="`${$t('Length4')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.length4"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
              </div>



            </b-collapse>
          </b-tab>
          <b-tab
            :title="$t('Back Body')"
          >
            <h4 class="headline">
              {{ $t('') }}
            </h4>
            <b-collapse
              id="collapse-345"
              visible
            >
              <div class="hello">
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"
                  :label="`${$t('Shoulders')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.shoulders"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"
                  :label="`${$t('Back')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.back"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
              </div><div class="hello">
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"
                  :label="`${$t('Shoulders')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.shoulders"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"
                  :label="`${$t('Back')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.back"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="hello">
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"
                  :label="`${$t('Ankle')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.ankle"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"
                  :label="`${$t('AroundNeck')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.aroundNeck"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
              </div>

              <div class="hello">
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"
                  :label="`${$t('SleeveLength')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.sleeveLength"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"
                  :label="`${$t('AroundSleeve')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.aroundSleeve"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="hello">
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"
                  :label="`${$t('Elbow')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.elbow"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  style="width: 100%"
                  :label="`${$t('BackBody')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.backBody"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="hello">
                <b-form-group
                  id="input-group-1"
                  style="width: 50%"
                  :label="`${$t('DeclineOfTheHips')}:`"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="formm.bodyMeasurements.declineOfTheHips"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                </b-form-group>
              </div>
            </b-collapse>
          </b-tab>

        </b-tabs>
      </section> -->



      <section
        v-if="getEditOrder.bodyMeasurements !== null"
        class="custom-modal__wrapper"
        style="margin-bottom: 20px; flex-direction: column"
      >
        <!-- <div
          style="display: flex"
        >
          <h4
            class="headline"
          >
            {{ $t('CustomSize') }}
          </h4>
        </div>
        <div
          style="display: flex"
        >
          <h4
            class="headline"
          >
            {{ $t('StandardSize') }}
          </h4>
        </div> -->
        <!-- <b-collapse
          :id="`collapse`"
          visible
        >
          <p style="margin-left: 7px">
            v-model="dress.existingSizes"
            <b-form-checkbox
              id="checkbox-sizes"
              :name="`checkbox`"
              :value="true"
              :unchecked-value="false"
            >
              {{ $t('Regular Sizes') }}
            </b-form-checkbox>
          </p>
        </b-collapse> -->
        <!-- v-if="getEditOrder.bodyMeasurements !== null" -->
        <div
          v-if="client == 'ValdrinSahiti'"
          class="skica-container"
        >
          <b-collapse
            :id="`collapse-${idx}`"
            visible
          >
            <div
              class="front-body"
            >

              <div class="betweenCol">
                <div class="input">
                  <span>{{ $t('toraks') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.thorax"
                    :placeholder="$t('')"
                    class="toraks1"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('Bust') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.aroundBust"
                    :placeholder="$t('')"
                    class="rrethiGjoksit1"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('UnderBust') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.aroundUnderBust"
                    :placeholder="$t('')"
                    class="rrethiNenGjoksit1"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('Abdomen') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.waist"
                    :placeholder="$t('')"
                    class="beli1"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('Hips') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.aroundHips"
                    :placeholder="$t('')"
                    class="vithet1"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('BustReduction') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.aroundLeg"
                    :placeholder="$t('')"
                    class="rrethiKembes1"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('UnderBustReduction') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.aroundKnee"
                    :placeholder="$t('')"
                    class="rrethiGjurit1"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('FrontBody') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.ankle"
                    :placeholder="$t('')"
                    class="zoguKembeve1"
                  />
                </div>

              </div>
              <div class="betweenCol">


                <div class="input">
                  <span>{{ $t('BackBody') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.breastReduction"
                    :placeholder="$t('')"
                    class="reniaGjoksit1"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('Shoulders') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.chestDistance"
                    :placeholder="$t('')"
                    class="distancaGjoksit1"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('ShouldersSeam') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.sleeveLength"

                    :placeholder="$t('')"
                    class="distancaGjoksit1"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('Distance') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.interBust"
                    :placeholder="$t('')"
                    class="nderGjoks1"
                  />
                </div>

                <div class="input">
                  <span>{{ $t('ButtocksReduction') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.frontBody"
                    :placeholder="$t('')"
                    class="trupiPara1"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('Height') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.length1"
                    :placeholder="$t('')"
                    class="gjatesia11"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('3QuartersLength') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.length2"
                    :placeholder="$t('')"
                    class="gjatesia21"
                  />
                </div>
              </div>
              <div class="betweenCol">

                <div class="input">
                  <span>{{ $t('ChanelLength') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.length3"
                    :placeholder="$t('')"
                    class="gjatesia31"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('MiniLength') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.length4"
                    :placeholder="$t('')"
                    class="gjatesia41"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('SleeveCircumference') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.aroundSleeve"
                    :placeholder="$t('')"
                    class="rrethiMenges1"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('SleeveLength') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.elbow"
                    :placeholder="$t('')"
                    class="brryli1"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('M.3QuartersLength') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.wrist"
                    :placeholder="$t('')"
                    class="kyçi1"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('ElbowCircumference') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.aroundNeck"
                    :placeholder="$t('')"
                    class="rrethiQafes1"
                  />
                </div>
                <div class="input">
                  <span>{{ $t('WristCircumference') }}</span>
                  <b-form-input
                    v-model="formm.bodyMeasurements.backBody"
                    :placeholder="$t('')"
                    class="trupiPrapa1"
                  />
                </div>
              </div>
            </div>

          </b-collapse>
        </div>
        <div
          v-else
          class="skica-container"
        >
          <b-collapse
            :id="`collapse`"
            visible
          >
            <div
              class="btn-con"
            >
              <button
                type="button"
                @click="showBody = !showBody"
              >
                {{ $t('FlipBody') }}
              </button>
            </div>
            <!-- v-if="dress.showFrontBody" -->
            <div
              v-if="showBody "
              class="front-body"
            >
              <img
                src="../../../assets/images/front-body.svg"
                alt=""
              >
              <div class="toraks">
                <span>1.</span>
                <!-- v-model="dress.sizesDto.thorax" -->
                <b-form-input
                  v-model="formm.bodyMeasurements.thorax"
                  :placeholder="$t('toraks')"
                  class="toraks1"
                />
              </div>
              <div class="rrethiGjoksit">
                <span>2.</span>
                <!-- v-model="dress.sizesDto.aroundBust" -->
                <b-form-input
                  v-model="formm.bodyMeasurements.aroundBust"
                  :placeholder="$t('rrethiGjoksit')"
                  class="rrethiGjoksit1"
                />
              </div>
              <div class="rrethiNenGjoksit">
                <span>3.</span>
                <!-- v-model="dress.sizesDto.aroundUnderBust" -->
                <b-form-input
                  v-model="formm.bodyMeasurements.aroundUnderBust"
                  :placeholder="$t('rrethiNenGjoksit')"
                  class="rrethiNenGjoksit1"
                />
              </div>
              <div class="beli">
                <span>4.</span>
                <!-- v-model="dress.sizesDto.waist" -->
                <b-form-input
                  v-model="formm.bodyMeasurements.waist"
                  :placeholder="$t('beli')"
                  class="beli1"
                />
              </div>
              <div class="vithet">
                <span>5.</span>
                <!-- v-model="dress.sizesDto.aroundHips" -->
                <b-form-input
                  v-model="formm.bodyMeasurements.aroundHips"
                  :placeholder="$t('RrethiVitheve')"
                  class="vithet1"
                />
              </div>
              <div class="rrethiKembes">
                <span>6.</span>
                <!-- v-model="dress.sizesDto.aroundLeg" -->
                <b-form-input
                  v-model="formm.bodyMeasurements.aroundLeg"
                  :placeholder="$t('rrethiKembes')"
                  class="rrethiKembes1"
                />
              </div>
              <div class="rrethiGjurit">
                <span>7.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.aroundKnee"
                  :placeholder="$t('rrethiGjurit')"
                  class="rrethiGjurit1"
                />
              </div>
              <div class="zoguKembeve">
                <span>8.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.ankle"
                  :placeholder="$t('zoguKembeve')"
                  class="zoguKembeve1"
                />
              </div>

              <div class="reniaGjoksit">
                <span>9.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.breastReduction"
                  :placeholder="$t('reniaGjoksit')"
                  class="reniaGjoksit1"
                />
              </div>
              <div class="distancaGjoksit">
                <span>10.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.chestDistance"
                  :placeholder="$t('distancaGjoksit')"
                  class="distancaGjoksit1"
                />
              </div>
              <div class="nderGjoks">
                <span>11.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.interBust"
                  :placeholder="$t('nderGjoks')"
                  class="nderGjoks1"
                />
              </div>
              <div class="trupiPara">
                <span>12.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.frontBody"
                  :placeholder="$t('trupiPara')"
                  class="trupiPara1"
                />
              </div>
              <div class="gjatesia1">
                <span>13.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.length1"
                  :placeholder="$t('gjatesia1')"
                  class="gjatesia11"
                />
              </div>
              <div class="gjatesia2">
                <span>14.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.length2"
                  :placeholder="$t('gjatesia2')"
                  class="gjatesia21"
                />
              </div>
              <div class="gjatesia3">
                <span>15.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.length3"
                  :placeholder="$t('gjatesia3')"
                  class="gjatesia31"
                />
              </div>
              <div class="gjatesia4">
                <span>16.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.length4"
                  :placeholder="$t('gjatesia4')"
                  class="gjatesia41"
                />
              </div>
            </div>
            <!-- v-if="!dress.showFrontBody" -->
            <div
              v-if="!showBody"
              class="back-body"
            >
              <img
                src="../../../assets/images/back-body.svg"
                alt=""
              >
              <div class="rrethiMenges">
                <span>9.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.aroundSleeve"
                  :placeholder="$t('rrethiMenges')"
                  class="rrethiMenges1"
                />
              </div>
              <div class="brryli">
                <span>10.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.elbow"
                  :placeholder="$t('brryli')"
                  class="brryli1"
                />
              </div>
              <div class="kyçi">
                <span>11.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.wrist"
                  :placeholder="$t('kyqi')"
                  class="kyçi1"
                />
              </div>
              <div class="rrethiQafes">
                <span>12.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.aroundNeck"
                  :placeholder="$t('rrethiQafes')"
                  class="rrethiQafes1"
                />
              </div>
              <div class="trupiPrapa">
                <span>16.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.backBody"
                  :placeholder="$t('trupiPrapa')"
                  class="trupiPrapa1"
                />
              </div>
              <div class="supet">
                <span>17.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.shoulders"
                  :placeholder="$t('supet')"
                  class="supet1"
                />
              </div>
              <div class="shpina">
                <span>18.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.back"
                  :placeholder="$t('shpina')"
                  class="shpina1"
                />
              </div>
              <div class="reniaVitheve">
                <span>20.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.declineOfTheHips"
                  :placeholder="$t('reniaVitheve')"
                  class="reniaVitheve1"
                />
              </div>
              <div class="gjatesiaMenges">
                <span>21.</span>
                <b-form-input
                  v-model="formm.bodyMeasurements.sleeveLength"
                  :placeholder="$t('gjatesiaMenges')"
                  class="gjatesiaMenges1"
                />
              </div>
            </div>
          </b-collapse>
        </div>
      </section>
      <div>
        <div class="">
          <b-button
            v-b-toggle="`lapse-${idx}`"
            class="m-1"
            variant="light"
          >
            <b-icon-arrows-expand />
          </b-button>
          <span style="font-weight: 500">{{ $t('ShowMaterialVariant') }}</span>


          <b-collapse
            :id="`lapse-${idx}`"
            visible
          >
            <div
              class="modal-card__wrapper"
              style="align-items: center"
            >
              <div
                v-for="(item, index) in form"
                :key="index"
                class="modal-card"
              >
                <b-form-group
                  id="input-group-3"
                  :label="`${$t('SelectMaterialType')}:`"
                >
                  <i
                    class="modal-card__remove"
                    @click="removeMaterialItemFromCD(index)"
                  >
                    <b-icon-trash />
                  </i>
                  <div class="flex radio-form">
                    <div class="flex aic">
                      <input
                        :id="`one${index}`"
                        v-model="item.measurementType"
                        type="radio"
                        value="Meter"
                        @click="meter()"
                      >
                      <label :for="`one${index}`">{{ $t('Meter') }}</label>
                    </div>
                    <div class="flex aic">
                      <input
                        :id="`two${index}`"
                        v-model="item.measurementType"
                        type="radio"
                        value="Parts"
                        @click="part()"
                      >
                      <label :for="`two${index}`">  {{ $t('Part') }}</label>
                    </div>
                  </div>
                </b-form-group>
                <b-form-group
                  id="input-select-1"
                  :label="`${$t('MaterialVariant')}:`"
                  label-for="select-1"
                >
                  <vue-select
                    v-if="item.measurementType === 'Meter'"
                    v-model="item.name"
                    :options="getFilteredMV.length == 0 ? getMaterialsVByMeter : getFilteredMV"
                    :placeholder="$t('MaterialVariant')"
                    :reduce="(e) => e"
                    label="materialVariantName"
                    @search="onSearchM"
                  >
                    <li
                      slot="list-footer"
                      class="pagination"
                      style="display: flex; width: 100%; justify-content: center"
                    >
                      <button
                        type="button"
                        :style="
                          pageM == 1
                            ? 'width: 50%; border: none;'
                            : 'width: 50%; border: none; background-color: #242F6E; color: white'
                        "
                        :disabled="pageM == 1"
                        @click="prevMeter"
                      >
                        {{ $t('Prev') }}
                      </button>
                      <button
                        :style="
                          pageM == getTotalPagesForMVM
                            ? 'width: 50%; border: none;'
                            : 'width: 50%; border: none; background-color: #242F6E; color: white'
                        "
                        type="button"
                        :disabled="pageM == getTotalPagesForMVM"
                        @click="nextMeter"
                      >
                        {{ $t('Next') }}
                      </button>
                    </li>
                  </vue-select>
                  <vue-select
                    v-else
                    v-model="item.name"
                    :options="getFilteredMV.length == 0 ? getMaterialsVByPart : getFilteredMV"
                    :placeholder="$t('MaterialVariant')"
                    :reduce="(e) => e"
                    label="materialVariantName"
                    @search="onSearchP"
                  >
                    <li
                      slot="list-footer"
                      class="pagination"
                      style="display: flex; width: 100%; justify-content: center"
                    >
                      <button
                        type="button"
                        :style="
                          pageM == 1
                            ? 'width: 50%; border: none;'
                            : 'width: 50%; border: none; background-color: #242F6E; color: white'
                        "
                        :disabled="pageP == 1"
                        @click="prevPart"
                      >
                        {{ $t('Prev') }}
                      </button>
                      <button
                        type="button"
                        :style="
                          pageP == getTotalPagesForMVP
                            ? 'width: 50%; border: none;'
                            : 'width: 50%; border: none; background-color: #242F6E; color: white'
                        "
                        :disabled="pageP == getTotalPagesForMVP"
                        @click="nextPart"
                      >
                        {{ $t('Next') }}
                      </button>
                    </li>
                  </vue-select>
                </b-form-group>


                <b-form-group
                  v-if="item.measurementType === 'Meter'"
                  :label="`${$t('Meter')}:`"
                >
                  <b-input
                    v-model="item.length"
                    type="number"
                    @input="valid(item.length,index)"
                  />
                  <p
                    v-if="item.length < 0.0001 "
                    style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                  >
                    {{ $t('TheValue') }}
                  </p>
                </b-form-group>

                <b-form-group
                  v-else
                  :label="`${$t('Part')}:`"
                >

                  <b-input
                    v-model="item.quantity"
                    type="number"
                    @input="valid(item.quantity,index)"
                  />
                  <p
                    v-if="item.quantity < 0.0001 "
                    style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                  >
                    {{ $t('TheValue') }}
                  </p>
                </b-form-group>
              </div>
              <b-button
                type="button"
                size="sm"
                style="margin: 20px 0; background: #FF274F;"
                @click="addNewRowForCD()"
              >
                <p style="margin: 0">
                  + {{ $t('AddMaterial') }}
                </p>
              </b-button>
            </div>
          </b-collapse>
        </div>
      </div>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { mapGetters, mapActions } from 'vuex'
import { client } from '../../../domainConfig'



export default {
  components: {
    flatPickr,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['articleNumber'],
  data() {
    return {
      client: client.clientName,
      showLengthError: false,
      formm: {
        orderItemId: null,
        bodyMeasurements: {
          thorax: null,
          aroundBust: null,
          aroundUnderBust: null,
          waist: null,
          aroundHips: null,
          aroundLeg: null,
          aroundKnee: null,
          aroundNeck: null,
          aroundSleeve: null,
          ankle: null,
          breastReduction: null,
          chestDistance: null,
          interBust: null,
          frontBody: null,
          length1: null,
          length2: null,
          length3: null,
          length4: null,
          elbow: null,
          wrist: null,
          back: null,
          backBody: null,
          shoulders: null,
          declineOfTheHips: null,
          sleeveLength: null,
        },
        articleNumber: '',
        size: '',
        pickUpDate: '',
        price: 0,
        paidAmount: 0,
        materialVariantInStoreResponseDtos: [],
      },
      isUndefined: false,
      expand: false,
      index: 0,
      pageM: 1,
      pageP: 1,
      pageSize: 15,
      radio: null,
      idx: 0,
      sizeOptions: [
        {
          size: 'xs',
        },
        {
          size: 's',
        },
        {
          size: 'm',
        },
        {
          size: 'l',
        },
        {
          size: 'xl',
        },
      ],
      showBody: true,
      form: [],
    }
  },
  computed: {
    ...mapGetters(['getEditOrder', 'getEditOrderItemMaterialVariant', 'getCurrentPageForMVP', 'getMaterialsVByMeter',
      'getMaterialsVByPart', 'getFilteredMV', 'getTotalPagesForMVP', 'getTotalPagesForMVM', 'getCurrentPageForMVM', 'getCurrentPageForMVP', 'getMeasermentsById']),
  },
  watch: {
    getEditOrder: {
      handler(newValue) {
        this.formm.bodyMeasurements = newValue.bodyMeasurements;
        this.formm.orderItemId = newValue.orderItemId
        this.formm.paidAmount = newValue.paidAmount
        this.formm.pickUpDate = newValue.pickUpDate
        this.formm.price = newValue.price
        this.formm.size = newValue.size
        this.formm.articleNumber = newValue.articleNumber
      },
    },
    getEditOrderItemMaterialVariant() {
      this.fillForm()
    },
  },
  mounted() {
    // console.log('articlee', this.getEditOrder.articleNumber)
    // this.formm.bodyMeasurements = this.getEditOrder.bodyMeasurements;
    // this.formm.articleNumber = this.getEditOrder.articleNumber
    // this.formm.materialVariantInStoreResponseDtos = this.getEditOrderItemMaterialVariant

    // this.getEditOrderItemMaterialVariant.materialVariantId = this.getEditOrderItemMaterialVariant.name.materialVariantId
    // this.getEditOrderItemMaterialVariant.name = this.getEditOrderItemMaterialVariant.name.materialVariantName
  },
  methods: {
    ...mapActions(['getMaterialVariantsToMDCPagination', 'edit_OrderItem', 'getMaterialVariantsToMDCPagination', 'testResetMV', 'loadMaterialVaraintsCategoryNameLike']),
    addNewRowForCD() {
      this.form.push({
        // materialVariantId: '',
        // measurementType: 'Meter',
        // name: '',
        // length: 0,
        // quantity: 0,
        isEditable: true,
        isStockAvailable: true,
        length: 0,
        materialVariantId: null,
        measurementType: null,
        name: null,
        quantity: 0,
      });
    },
    valid(item) {
      if (item < 0.001) {
        this.showLengthError = true;
      } else {
        this.showLengthError = false;
      }
    },
    fillForm() {
      this.form = []
      for (let i = 0; i < this.getEditOrderItemMaterialVariant.length; i++) {
        this.form.push(this.getEditOrderItemMaterialVariant[i])
      }
    },
    removeMaterialItemFromCD(index) {
      this.getEditOrderItemMaterialVariant.splice(index, 1)
    },
    meter() {
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      })
    },
    part() {
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      })
    },
    onSubmit() {
      const materialVariants = []
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.form) {
        if (typeof item.name === 'object') {
          materialVariants.push({
            name: item.name.materialVariantName, measurementType: item.measurementType, materialVariantId: item.name.materialVariantId, length: item.length, quantity: item.quantity,
          })
        } else {
          materialVariants.push({
            name: item.name, measurementType: item.measurementType, materialVariantId: item.materialVariantId, length: item.length, quantity: item.quantity,
          })
        }
      }
      if (this.showLengthError == true) {
        return;
      }

      // console.log('materialVariants', materialVariants)

      this.formm.materialVariantInStoreResponseDtos = materialVariants

      console.log('formw', this.formm)

      this.$emit('editOrderItemm', this.formm)
      this.onCancel()

      // this.edit_OrderItem({
      //   object: this.formm,
      //   successCallback: () => {

      //   },
      // })
    },
    removeProbeDate(index) {
      this.dress.probeDates.splice(index, 1);
    },
    addNewDate() {
      this.dress.probeDates.push(
        {
          probeName: `${this.$t('FitDate')} ${this.index += 1}`,
          dateOfProbe: '',
          notes: null,
        },
      );
    },
    // updateSelectedValue() {
    //   this.$emit('selected-value-change', this.idx, this.selectedValue);
    // },
    removeCustomDress(index) {
      this.$emit('sendIndex', index)
    },
    async getMVbyMeter(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Meter',
      });
    },
    async getMVbyPart(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Part',
      });
    },
    nextMeter() {
      if (this.pageM < this.getTotalPagesForMVM) {
        this.pageM += 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      }
    },
    prevMeter() {
      if (this.pageM > 1) {
        this.pageM -= 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      }
    },
    nextPart() {
      if (this.pageP < this.getTotalPagesForMVP) {
        this.pageP += 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      }
    },
    prevPart() {
      if (this.pageP > 1) {
        this.pageP -= 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      }
    },
    async onSearchM(query) {
      await this.loadMaterialVaraintsCategoryNameLike({
        name: query,
        measurementType: 'Meter',
      });
      if (query == '') {
        this.testResetMV()
        this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
      }
    },
    async onSearchP(query) {
      await this.loadMaterialVaraintsCategoryNameLike({
        name: query,
        measurementType: 'Part',
      });
      if (query == '') {
        this.testResetMV()
        this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
      }
    },
    onCancel() {
      this.$refs.modal.hide();
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
      setTimeout(() => {
        // this.resetForm();
        this.materials = [];
      }, 10);
    },
  },

};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.hello{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.headline {
  padding: 5px 10px;
  background-color: $base-color;
  color: $digit-white;
  border-radius: 8px;
}

.custom-modal__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;

  >div {
    flex: 1;
    margin: 0px 15px;
  }
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.fixed-height-modal {
  height: calc(100vh - 160px);
  overflow-y: auto;
  padding-right: 1rem;
}

.customDress-container {
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}

.customDress-container:last-child {
  margin-bottom: 20px;
}

.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 2px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #242F6E;
  color: #fff;
}

.f {
  border-color: red !important;
}
.front-body {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-bottom: 20px;
  img {
    width: 100%;
  }
  .betweenCol{
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .input{
    max-width: 100%;
    height: 51px;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .toraks {
    display: flex;
    position: absolute;
    top: 106px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjoksit {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiNenGjoksit {
    display: flex;
    position: absolute;
    top: 165px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .beli {
    display: flex;
    position: absolute;
    top: 200px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .vithet {
    display: flex;
    position: absolute;
    top: 263px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiKembes {
    display: flex;
    position: absolute;
    bottom: 222px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjurit {
    display: flex;
    position: absolute;
    bottom: 148px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .zoguKembeve {
    display: flex;
    position: absolute;
    bottom: 38px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .reniaGjoksit {
    display: flex;
    position: absolute;
    top: 85px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .nderGjoks {
    display: flex;
    position: absolute;
    top: 158px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .trupiPara {
    display: flex;
    position: absolute;
    top: 200px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .distancaGjoksit {
    display: flex;
    position: absolute;
    top: 128px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia1 {
    display: flex;
    position: absolute;
    bottom: 222px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia2 {
    display: flex;
    position: absolute;
    bottom: 127px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia3 {
    display: flex;
    position: absolute;
    bottom: 68px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia4 {
    display: flex;
    position: absolute;
    bottom: 8px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}
.btn-con {
  display: flex;
  button {
    border: none;
    background: #FF274F;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}
.back-body {
  position: relative;
  img {
    width: 100%;
  }
  .rrethiMenges {
    display: flex;
    position: absolute;
    top: 126px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .brryli {
    display: flex;
    position: absolute;
    top: 156px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .kyçi {
    display: flex;
    position: absolute;
    top: 192px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiQafes {
    display: flex;
    position: absolute;
    top: 60px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .trupiPrapa {
    display: flex;
    position: absolute;
    top: 202px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .supet {
    display: flex;
    position: absolute;
    top: 97px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .shpina {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .reniaVitheve {
    display: flex;
    position: absolute;
    bottom: 253px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesiaMenges {
    display: flex;
    position: absolute;
    top: 95px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}
@media only screen and (max-width: 1200px){
  .front-body {
  position: relative;
  .toraks {
    top: 65px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjoksit {
    position: absolute;
    top: 95px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiNenGjoksit {
    position: absolute;
    top: 125px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .beli {
    position: absolute;
    top: 155px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .vithet {
    position: absolute;
    top:249px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiKembes {
    position: absolute;
    bottom: 65px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjurit {
    position: absolute;
    bottom: 35px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .zoguKembeve {
    position: absolute;
    bottom: 5px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .reniaGjoksit {
    position: absolute;
    top: 65px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .nderGjoks {
    position: absolute;
    top: 125px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .trupiPara {
    position: absolute;
    top: 155px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .distancaGjoksit {
    position: absolute;
    top: 95px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia1 {
    position: absolute;
    bottom: 95px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia2 {
    position: absolute;
    bottom: 65px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia3 {
    position: absolute;
    bottom: 35px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia4 {
    position: absolute;
    bottom: 5px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
}
.btn-con {
  display: flex;
  button {
    border: none;
    background-color: #6c757d;;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}
.back-body {
  position: relative;
  img {
    width: 100%;
  }
  .rrethiMenges {
    position: absolute;
    top: 126px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .brryli {
    position: absolute;
    top: 156px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .kyçi {
    position: absolute;
    top: 192px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiQafes {
    position: absolute;
    top: 70px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .trupiPrapa {
    position: absolute;
    top: 215px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .supet {
    position: absolute;
    top: 97px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .shpina {
    position: absolute;
    top: 70px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .reniaVitheve {
    position: absolute;
    bottom: 100px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesiaMenges {
    position: absolute;
    top: 98px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
}
}
input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -3px;
        left: -1px;
        position: relative;
        background-color: $base-color ;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }
@media only screen and (max-width: 800px) {
  .front-body {
    .vithet {
      position: absolute;
    top: 195px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
    }
  }
}

</style>
