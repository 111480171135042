<template>
  <div>
    <b-modal
      id="sell-article-modal"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('SellArticle')"
      hide-footer
      @close="onReset"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('TeamN')}:`"
          label-for="input-1"
        >
          <vue-select
            v-model="team"
            :options="getTeamsNames"
            :placeholder="$t('SelectTeam')"
            label="teamName"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-group />

          <div class="buttonsEverywhere">
            <b-button
              type="button"
              variant="none"
              class="buttonSubmit"
              @click="onSubmit"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="danger"
              class="buttonCancel"
              style="margin-right: 15px"
              @click="onReset"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form-group></b-form>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
// import {
//   required, minLength,
// } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex'

export default {
  mixins: [validationMixin],

  // eslint-disable-next-line vue/require-prop-types
  props: ['orderNumber', 'articleName'],
  data() {
    return {
      formm: {
        orderItemId: null,
        articleNumber: null,
        assignmentTeamNameId: null,
      },
      team: null,
    }
  },
  // validations: {
  //   form: {
  //     name: {
  //       required,
  //       minLength: minLength(3),
  //     },
  //   },
  // },
  computed: {
    ...mapGetters(['getTeamsNames']),
  },
  watch: {
    editedMaterial() {
      this.name()
    },
    orderNumber(value) {
      this.formm.orderItemId = value
    },
    articleName(value) {
      this.formm.articleNumber = value
    },
    team(value) {
      this.formm.assignmentTeamNameId = value.teamNameId
    },
  },
  mounted() {
    this.form = this.dress
  },
  methods: {
    ...mapActions(['sell_productInStock']),
    // validateState(name) {
    //   const { $dirty, $error } = this.$v.form[name];
    //   return $dirty ? !$error : null;
    // },

    name() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.form.name = this.editedMaterial.name
      this.form.description = this.editedMaterial.description
    },
    onSubmit() {
      // this.$v.form.$touch();
      // if (this.$v.form.$anyError) {
      //   return;
      // }
      this.$emit('sellDresss', this.formm)
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset()
    },

    // this.sell_productInStock({
    //   orderItemId: this.formm.orderItemId,
    //   articleNumber: this.formm.articleNumber,
    //   assignmentTeamNameId: this.formm.assignmentTeamNameId,
    // })
    onReset() {
      this.$refs.modal.hide()
      this.resetForm()
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
    },
    resetForm() {
      // this.team.teamName = ''
      // this.team.teamNameId = ''
      this.team = null
      this.formm.assignmentTeamNameId = null
      this.formm.articleNumber = null
      this.formm.orderItemId = null
    },
  },
}
</script>

<style lang="scss" scoped>
  // form {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   gap: 10px;
  // }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
</style>
